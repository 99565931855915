import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import TopGuideCard from './topGuideCard'
import TopManageCard from './topManageCard'
import TopSettingCard from './topSettingCard'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 55px 0 0px 0;

  ${BreakPoints.large} {
    flex-direction: column;
    margin-bottom: ;
  }
`

/**
 * TOPよくあるご質問カード
 * @constructor
 */
const TopMenuArea = () => (
  <Wrapper>
    <TopGuideCard />
    <TopManageCard />
    <TopSettingCard />
  </Wrapper>
)

export default TopMenuArea
